@import './file/fileLink';
@import './search';
@import './blog/blogList';
@import './blog/blogView';

// .multiColumnPanel .htmlPanel {
//   padding: 0 1em;
// }

.slick-slide.slick-current {
  z-index: 2;
}