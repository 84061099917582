$themes: (
    red : ($color-red),
    green : ($color-green)
);

@each $theme-code, $params in $themes{
    $color: nth($params, 1);
    div.body-content.color--theme--#{$theme-code}{
        .cms_item.htmlPanel{
            h1 b, strong,
            h2 b, strong,
            h3 b, strong,
            h4 b, strong,
            a{
                color: $color;
                &:hover{
                    color: $link-hover-color;
                }
            }
        }
        .contentPanel a.button, .actions button{
            background-color: $color;
            border-color: $color;
            color: white;
            &:hover{
                color: white;
                background-color: $link-hover-color;
                border-color: $link-hover-color;
            }
        }
        // .pageTile__bsyc__content__details{
        //     background-color: rgba($color, 0.5);
        // }
        // .pageTile__bsyc__content:hover .pageTile__bsyc__content__details{
        //     background-color: rgba($color, 1);
        // }
    }
}